import React from "react";
import { useState, useRef, useEffect } from "react";
import { ReactComponent as ShareIcon } from "../../../images/svg/share.svg";
import { ReactComponent as DownArrow } from "../../../images/svg/down-arrow.svg";
import freestat from "../../../images/freestat.jpg";
import sky from "../../../images/sky.jpg";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
function Sattelite() {
  let shareRef = useRef(null);
  let shareIconRef = useRef(null);
  const [shareButton, setShareButton] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    let handleOutClick = (e) => {
      if (!shareIconRef?.current?.contains(e.target)) {
        if (!shareRef?.current?.contains(e.target)) {
          setShareButton(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [shareRef]);
  return (
    <div className="satteliteDevices">
      <div className="wrapper">
        <h1 className="heading">Satellite -how to watch</h1>

        <div className="contents">
          <div className="sections">
            <div className="imageContainer">
              <img src={sky} />
              <img src={freestat} />
            </div>
            <div className="description">
              <p>
                <strong>Tune into Revelation TV on SKY TV & Freesat</strong>
              </p>
              <br></br>
              <ul className="content-list">
                <li>Viewing us on SKY TV - tune to channel 581</li>
                <li>Viewing us on Freesat - tune to channel 692</li>
              </ul>
              <p>
                <strong>How to watch on the Christian TV Box</strong>
              </p>
              <p>
                If you have a dish pointing at the SKY satellite position then you
                can simply plug its lead into your box and tune in with the details
                below:
              </p>

              <ul className="content-list" style={{marginBottom: '120px'}}>
                {/* <li>Frequency: 11,265MHz</li>
                <li>Polarization: Vertical</li>
                <li>Symbol Rate: 27.500</li>
                <li>FEC: 2/3</li> */}
                <li>Satellite: Astra 2G @28.2º East</li>
                <li>Transponder: 102</li>
                <li>Downlink Frequency: 11 656</li>
                <li>Downlink Polarity: Vertical (Y)</li>
                <li>Symbol Rate: 22.0</li>
                <li>FEC: 5/6</li>
                <li>Mod Cod: DVBS QPSK</li>
                <li>Roll off: 35%</li>
              </ul>
              {/* <p>
                Further information{" "}
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => window.open("https://www.christiantv.org.uk/")}
                >
              //www.christiantv.org.uk/
                </span>
              </p> */}
              <p >
                <strong>
                  For those installing dishes themselves (or on steerable dishes)
                </strong>
              </p>
              <p >
                We are on the Astra 2G satellite 28.2E
              </p>
            </div>
          </div>
          <div className="date">
            <div className="icon">
              <img
                src={require("../../../images/icon-calendartime.png")}
                alt="Calender"
              />
            </div>
            <span>13 Nov 2019</span>
          </div>

          <div className="buttonContainer">
            <button ref={shareIconRef} onClick={() => setShareButton(!shareButton)} className={shareButton ? "active shareButton " : "shareButton"}>
              <ShareIcon />
              <span>Share</span>

              <DownArrow className='active' />
            </button>
            {
              shareButton &&
              <div className="shareContainer" ref={shareRef}>
                <FacebookShareButton
                  url={window.location.href}
                >
                  <span className='name facebook'>Facebook</span>
                </FacebookShareButton>
                <TwitterShareButton
                  url={window.location.href}
                >
                  <span className='name twitter'>Twitter</span>
                </TwitterShareButton>
                <CopyToClipboard text={window.location.href}>
                  <button className='clipboard' onClick={() => alert("Link copied to clipboard")}>Clipboard</button>
                </CopyToClipboard>
                <EmailShareButton
                  url={window.location.href}
                >
                  <span className='name'>Mail</span>
                </EmailShareButton>
                <WhatsappShareButton url={window.location.href}>
                  <span className='name whatsapp'>Whatsapp</span>
                </WhatsappShareButton   >
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sattelite;
