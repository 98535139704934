import React, { useEffect, useState } from "react";
import { ReactComponent as DownArrow } from "../../images/svg/down-arrow.svg";
import { Service } from "./Service";
import GetNextDates from "get-next-dates";
import { convertTimeToLocal } from "../../Utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Clock } from "../../images/svg/clock.svg";
import { ReactComponent as Bell } from "../../images/svg/bell.svg";
import dateFormat from "dateformat";
import { ReactComponent as Close } from "../../images/svg/closeIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { getSchedule } from "../../Redux/ScheduleRefresh/ScheduleRefreshSlice";
const ScheduleDays = ({ title, liveSchedule, schedulepage, home }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [buttonId, setButtonId] = useState(-1);
  const [nextDays, setnextDays] = useState(GetNextDates(6));
  const todayDate = new Date();
  const scheduleRefresh = useSelector((state) => state?.scheduleRefresh?.value);

  let count = 3;
  const [Schedule, setSchedule] = useState([]);
  const [sliceCount, setSliceCount] = useState(count);
  const [showList, setShowList] = useState([]);
  const [EarlierShowFlag, setEarlierShowFlag] = useState(false);
  const [TodayFlag, setTodayFlag] = useState(true);
  const dispatch = useDispatch();
  const [nowPlaying, setNowPlaying] = useState();
  const user = useSelector((state) => state?.user?.value);

  useEffect(() => {
    scheduleChange(todayDate.toString().substring(4, 10));
  }, []);

  useEffect(() => {
    setLoading(true);
    Service.scheduleDetails(370).then((response) => {
      setSchedule(response.data);
      setLoading(false);

      let data = [];
      response.data.map((item, index) => {
        const itemStartrtTime = new Date(item.starttime);

        let videoDate = dateFormat(item.starttime, "mmm dd, yyyy")
          .toString()
          .substring(0, 6);
        if (
          videoDate === todayDate.toString().substring(4, 10) &&
          itemStartrtTime > todayDate
        ) {
          data = [...data, item];
        } else {
          if (item?.status === "NOW_PLAYING") {
            setNowPlaying(item);
          }
        }
        setShowList(data);
      });
    });
    dispatch(
      getSchedule({
        scheduleRefresh: "",
      })
    );
  }, []);

  useEffect(() => {
    if (scheduleRefresh) {
      Service.scheduleDetails(370).then((response) => {
        setSchedule(response.data);

        let data = [];
        response.data.map((item, index) => {
          const itemStartrtTime = new Date(item.starttime);

          let videoDate = dateFormat(item.starttime, "mmm dd, yyyy")
            .toString()
            .substring(0, 6);
          if (
            videoDate === todayDate.toString().substring(4, 10) &&
            itemStartrtTime > todayDate
          ) {
            data = [...data, item];
          }
          setShowList(data);
        });
      });
    }
  }, [scheduleRefresh]);

  const scheduleChange = (requestDate) => {
    resetRowsCount(count);

    setEarlierShowFlag(false);
    let data = [];

    Schedule.map((item, index) => {
      const itemStartrtTime = new Date(item.starttime);

      let videoDate = dateFormat(item.starttime, "mmm dd, yyyy")
        .toString()
        .substring(0, 6);
      if (videoDate === requestDate) {
        if (itemStartrtTime >= todayDate) {
          data = [...data, item];
        } else {
        }
      }
      setShowList(data);
    });
    if (todayDate.toString().substring(4, 10) === requestDate) {
      setTodayFlag(true);
    } else {
      setTodayFlag(false);
    }
  };

  const earlierShows = (requestDate) => {
    resetRowsCount();

    setShowList(todayDate);
    let data = [];
    Schedule.map((item, index) => {
      const itemStartrtTime = new Date(item.starttime);
      const itemEndTime = new Date(item.endtime);
      let videoDate = dateFormat(item.starttime, "mmm dd, yyyy")
        .toString()
        .substring(0, 6);
      if (videoDate === requestDate) {
        if (itemEndTime < todayDate) {
          data = [...data, item];
        } else {
        }
      }
      setShowList(data);
    });
  };

  const remindMeHandler = (id, flag, index) => {
    if (user) {
      Service.scheduleReminder(id, flag).then((response) => {
        if (response) {
          let showlist = [...showList];
          showlist[index].schedule_reminded = flag ? false : true;

          setShowList(showlist);
        }
      });
    } else {
      navigate(`/register-login`, { state: { path: location?.pathname } });
    }
  };

  const resetRowsCount = () => {
    setSliceCount(count);
  };

  const replaceImage = (error) => {
    error.target.src = require("../../images/thumb.png");
  };
  
  let showListItems = showList
  
  if(home){
    showListItems = showList.slice(0,2)
  }
  
  return (
    <div>
      {loading ? (
        <div className="loadingContainer">
          <div className="loadingOverlay"></div>
          <div className="spinnerIcon">
            <div className="inner lds-dual-ring"></div>
          </div>
        </div>
      ) : (
        <div className={`schedulebox ${home && 'paddingShowContainer'}`}>
          <div className="scheduleContainer mt-4">
            <h1 className={`heading ${home && 'marginScheduleContainer'} `}>{title}</h1>
            {!home && <div className="daysContainer">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={4}
                navigation={true}
                watchSlidesProgress
                loop={false}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },
                  980: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="day">
                    <button
                      className={buttonId === -1 ? "active" : undefined}
                      onClick={() => {
                        setButtonId(-1);

                        scheduleChange(todayDate.toString().substring(4, 10));
                      }}
                    >
                      Today
                    </button>
                  </div>
                </SwiperSlide>

                {nextDays &&
                  nextDays.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="day">
                        <button
                          className={index === buttonId ? "active" : undefined}
                          onClick={() => {
                            setButtonId(index);
                            scheduleChange(item.toString().substring(4, 10));
                          }}
                        >
                          {item.toString().substring(0, 3)} {item.getDate()}
                        </button>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>}
            {!home && <div
              className="earlierShows"
              style={{ display: !TodayFlag && "none" }}
            >
              <button
                onClick={() => {
                  setEarlierShowFlag(!EarlierShowFlag);
                  if (EarlierShowFlag) {
                    scheduleChange(todayDate.toString().substring(4, 10));
                  } else {
                    earlierShows(todayDate.toString().substring(4, 10));
                  }
                }}
              >
                Earlier Shows
                <DownArrow
                  className="ms-2"
                  style={{ transform: EarlierShowFlag && "scaleY(-1)" }}
                />
              </button>
            </div>}
          </div>
          <div className={`${home ? '' : 'mt-5'}`}>
            {nowPlaying &&
              EarlierShowFlag === false &&
              TodayFlag &&
              schedulepage && (
                <div className="showContainer" >
                  <div className={"details"}>
                    <h3>
                      <Clock className="me-1" />

                      {nowPlaying?.starttime &&
                        convertTimeToLocal(nowPlaying?.starttime)
                          .toString()
                          .toLowerCase()
                          .replace(" ", "")}
                    </h3>
                    <div className="contents d-flex">
                      <div className="left">
                        <div className="thumbnail">
                          <img
                            src={
                              "https://gizmeon.s.llnwi.net/vod/thumbnails/thumbnails/" +
                              nowPlaying?.thumbnail_350_200
                            }
                            onError={(e) => replaceImage(e)}
                            alt="Thumbnail"
                          />
                        </div>
                      </div>
                      <div className="right">
                        <h1 className="title">{nowPlaying?.video_title}</h1>
                        <p className="description">
                          {nowPlaying?.video_description}
                        </p>
                        <div className="buttonContainer">
                          {nowPlaying?.hide_on_demand === false && (
                            <button>
                              <span
                                className="onDemand"
                                onClick={() => {
                                  if (nowPlaying?.vanity_url) {
                                    navigate({
                                      pathname: `/${nowPlaying?.vanity_url}`,
                                    });
                                  } else {
                                    navigate({
                                      pathname: `/${nowPlaying?.show_id}`,
                                    });
                                  }
                                }}
                              >
                                On Demand
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {showListItems.length > 0
              ? showListItems.map((item, index) => (
                  <div className="showContainer" key={index}>
                    <div className={"details"}>
                      <h3>
                        <Clock className="me-1" />

                        {item?.starttime &&
                          convertTimeToLocal(item?.starttime)
                            .toString()
                            .toLowerCase()
                            .replace(" ", "")}
                      </h3>
                      <div className="contents d-flex">
                        <div className="left">
                          <div className="thumbnail">
                            <img
                              src={
                                "https://gizmeon.s.llnwi.net/vod/thumbnails/thumbnails/" +
                                item?.thumbnail_350_200
                              }
                              onError={(e) => replaceImage(e)}
                              alt="Thumbnail"
                            />
                          </div>
                        </div>
                        <div className="right">
                          <h1 className="title">{item?.video_title}</h1>
                          <p className="description">
                            {item?.video_description}
                          </p>
                          <div className="buttonContainer">
                            {item?.hide_on_demand === false && (
                              <button>
                                <span
                                  className="onDemand"
                                  onClick={() => {
                                    if (item?.vanity_url) {
                                      navigate({
                                        pathname: `/${item?.vanity_url}`,
                                      });
                                    } else {
                                      navigate({
                                        pathname: `/${item?.show_id}`,
                                      });
                                    }
                                  }}
                                >
                                  On Demand
                                </span>
                              </button>
                            )}
                            {!EarlierShowFlag &&
                              (!item.schedule_reminded ? (
                                <button
                                  onClick={() =>
                                    remindMeHandler(item?.id, false, index)
                                  }
                                >
                                  <span className="d-flex align-items-center">
                                    Remind Me
                                    <Bell className="ms-2" />
                                  </span>
                                </button>
                              ) : (
                                <button
                                  onClick={() =>
                                    remindMeHandler(item?.id, true, index)
                                  }
                                >
                                  Cancel Reminder
                                  <Close className="ms-2" />
                                </button>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : `No Scheduled Programs`}
          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduleDays;
