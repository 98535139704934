import React, { useEffect, useRef, useState } from 'react'
import ScheduleDays from '../../../Components/ScheduleDays/ScheduleDays'
import ScheduleCard from '../../../Components/ScheduleCard/ScheduleCard'
import { ReactComponent as DownArrow } from "../../../images/svg/down-arrow.svg"
import * as service from "./service"
import VideoJS from '../../../Components/VideoJsPlayer/VideoJS';
import { playerToken } from '../../../CommonService'
import useScrollPosition from '../../../Components/ScrollPositon/useScrollPosition'
import { convertTimeToLocal } from '../../../Utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { getSchedule } from '../../../Redux/ScheduleRefresh/ScheduleRefreshSlice'

let debounce = true
var videoStarted = false;
let seconds = 60
let prevTime
const Live = ({home=false}) => {
  const [count, setCount] = useState(0);
  const [liveURL, setLiveURL] = useState();
  const [videoPlayer, setVideoPlayer] = useState()
  const [nowPlaying, setNowPlaying] = useState()
  const [loading, setLoading] = useState(true)
  const scrollPosition = useScrollPosition()
  const playerRef = React.useRef(null);
  const dispatch=useDispatch()
  const scheduleRefresh = useSelector((state)=>state?.scheduleRefresh?.value)

  

  useEffect(() => {
    setLoading(true)
    service.getLiveChannels().then((response) => {
      setLiveURL(response?.data[0]?.live_link)
      setNowPlaying(response?.data[0]?.now_playing)
      setLoading(false)
      localStorage.setItem("liveTriggered","false")
    })
  }, [])

  useEffect(() => {
    if(nowPlaying){

      const interval = setInterval(() => {
        setCount(count => count + 1);
        const localDate = new Date();
        let currentTime = convertTimeToLocal(localDate)
        let endTime = convertTimeToLocal(nowPlaying?.end_time)
        let liveTriggered = localStorage.getItem("liveTriggered")
        
        if (currentTime === endTime) {
          if(liveTriggered==="false"){
            service.getLiveChannels().then((response) => {
              if(response?.success === true){
                setNowPlaying(response?.data[0]?.now_playing)
                localStorage.setItem("liveTriggered","true")
                dispatch(getSchedule({
                  scheduleRefresh:response?.data[0]?.now_playing
                }))
              }
            })
          }
        }
      }, 3 * 1000);
      return () => {clearInterval(interval);localStorage.setItem("liveTriggered","false")};
    }



  }, [nowPlaying])

  useEffect(()=>{
    if(scheduleRefresh){
      setTimeout(() => {
        localStorage.setItem("liveTriggered","false")
      }, 65* 1000);
    }
  },[scheduleRefresh])

  useEffect(() => {

    if (liveURL) {
      const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        poster: require("../../../images/player.png"),
        sources: [{
          src: `${liveURL}`,
          type: 'application/x-mpegURL'
        }]
      };

      setVideoPlayer(
        <VideoJS onReady={handlePlayerReady} options={videoJsOptions} />

      )
    }
  }, [liveURL, scrollPosition])

  const handlePlayerReady = (player) => {
    let is_live = "1"

    playerRef.current = player;
    // You can handle player events here, for example:
    
    player.on("loadedmetadata", () => {
      

    })

    player.on("play", () => {
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;
      service.onVideoPlayFunction(nowPlaying, event, player.currentTime(), is_live).then((response) => {
      })
    })

    player.on("timeupdate", function (e) {
      let event = "POP03";

      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        service.onVideoPlayFunction(nowPlaying, event, player.currentTime(), is_live).then((response) => {
        })
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });



    player.on("pause", () => {
      let event = "POP04";
      service.onVideoPlayFunction(nowPlaying, event, player.currentTime(), is_live).then((response) => {
      })
    })
    player.on("ended", () => {

      let event = "POP05";
      service.onVideoPlayFunction(nowPlaying, event, player.currentTime(), is_live).then((response) => {
      })

    });

    player.on("dispose", () => {
      videoStarted = false
      player.pause()
    })

    //  
  };
  return (
    <>
      {
        loading ? (
          <div className="loadingContainer" >
            <div className="loadingOverlay"></div>
            <div className="spinnerIcon">

              <div className="inner lds-dual-ring"></div>
            </div>
          </div>
        ) : (

          <div className='liveContainer'>
            <div className="wrapper">
              <div className="liveVideoContainer mb-4">
                {
                  videoPlayer && videoPlayer
                }
              </div>
              <h1 className='nowPlaying'>Now Playing</h1>
              <ScheduleCard
                hover="hover"
                startTime={nowPlaying?.start_time}
                thumbnail={nowPlaying?.thumbnail_350_200}
                title={nowPlaying?.video_title}
                description={nowPlaying?.video_description}
                showId={nowPlaying?.vanity_url ? nowPlaying?.vanity_url : nowPlaying?.show_id}
                data={nowPlaying}
              />
              <ScheduleDays title="Coming Up" liveSchedule={nowPlaying}  home={home}/>
            </div>
          </div>
        )
      }
    </>
  )
}

export default Live